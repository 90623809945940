import { RefObject, useMemo } from "react";

import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import InputSearchWithOptionsV2, {
  InputSearchWithOptionsHandlerList,
} from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

import { getOptionList, getPortType, getRecommendationList } from "./utils";

import { useSearch } from "./SearchContext";

export default function Pod({
  podOptionHandlerListRef,
}: {
  podOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
}) {
  const [{ freightType, podId }, setSearch] = useSearch();

  const { data: podList } = COMMON_QUERY.useGetUserPortList({
    portType: getPortType(freightType),
    country: "KR",
  });

  const podOptionList = useMemo(() => getOptionList(podList), [podList]);

  const podRecommendationList = useMemo(
    () =>
      getRecommendationList({
        locationType: "pod",
        freightType,
        list: podList,
      }),
    [freightType, podList]
  );

  const handlePodSelect = (selectedPodId: number | null) => {
    setSearch({ podId: selectedPodId });
  };

  const handlePodReset = () => {
    setSearch({ podId: null });
  };

  return (
    <InputSearchWithOptionsV2<number | null>
      className="custom-label-color"
      width={296}
      labelInfo={{
        label: (
          <>
            도착지<span className="required">*</span>
          </>
        ),
        position: "top",
      }}
      placeholder="입력해주세요."
      searchSourceList={podOptionList}
      selectedOptionValue={podId}
      onSelect={handlePodSelect}
      onReset={handlePodReset}
      recommendedList={podRecommendationList}
      inputSearchWithOptionsHandlerListRef={podOptionHandlerListRef}
    />
  );
}
