import { RefObject } from "react";
import { useQueryClient } from "react-query";
import { useAtom } from "jotai";
import { useRouter } from "next/router";

import { SCHEDULE_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/SCHEDULE_QUERY";
import {
  OrderOption,
  SortOption,
} from "@sellernote/_shared/src/types/forwarding/schedule";
import { checkIsNull } from "@sellernote/_shared/src/utils/common/validation";
import Button from "@sellernote/_sds-v2/src/components/button/Button";
import { InputSearchWithOptionsHandlerList } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

import useScheduleSearchBarQueryStates from "../../hooks/forwarding/useScheduleSearchBarQueryStates";

import { FORWARDING_COMMON_ATOMS } from "../../jotaiStates/forwarding/common";
import { useSearch } from "./SearchContext";

export default function Search({
  polOptionHandlerListRef,
  podOptionHandlerListRef,
}: {
  polOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
  podOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
}) {
  const [perPage] = useAtom(FORWARDING_COMMON_ATOMS.FORWARDING_TABLE_PER_PAGE);

  const [{ freightType, polId, podId, etd, eta }] = useSearch();

  const [
    {
      minTransitDay,
      maxTransitDay,
      filterPolIds,
      filterPodIds,
      transit,
      linerIdList,
      sort,
      order,
      page,
    },
    setScheduleSearchBarQueryStates,
  ] = useScheduleSearchBarQueryStates();

  const { push, pathname } = useRouter();

  const queryClient = useQueryClient();

  const handleSearch = async () => {
    if (!polId) {
      polOptionHandlerListRef.current?.focusInput();
      return;
    }

    if (!podId) {
      podOptionHandlerListRef.current?.focusInput();
      return;
    }

    if (!etd) {
      return;
    }

    if (pathname === "/") {
      push(
        `/forwarding/schedule?${new URLSearchParams({
          freightType,
          polId: polId.toString(),
          podId: podId.toString(),
          etd,
          ...(eta ? { eta } : {}),

          page: "0",

          isSearchBar: "true",
        }).toString()}`
      );
      return;
    }

    await setScheduleSearchBarQueryStates({
      freightType,
      polId,
      podId,
      etd,
      eta,

      page: 0,

      minTransitDay: null,
      maxTransitDay: null,
      filterPodIds: null,
      filterPolIds: null,
      transit: null,
      linerIdList: null,

      sort: SortOption.TransitTimeInDays,
      order: OrderOption.Asc,

      isSearchBar: true,
    });

    // 초기 조회 조건과 동일한 조건으로 '조회' 버튼을 누른 경우 강제로 fetch 해준다.
    const isInitial =
      checkIsNull(minTransitDay) &&
      checkIsNull(maxTransitDay) &&
      checkIsNull(filterPolIds) &&
      checkIsNull(filterPodIds) &&
      checkIsNull(transit) &&
      checkIsNull(linerIdList) &&
      page === 0 &&
      sort === SortOption.TransitTimeInDays &&
      order === OrderOption.Asc;
    if (isInitial) {
      queryClient.invalidateQueries(
        SCHEDULE_QUERY_KEY_GEN.getScheduleList({
          freightType,
          polId,
          podId,
          etd,
          ...(eta ? { eta } : {}),

          perPage,
          page: 0,

          sort: SortOption.TransitTimeInDays,
          order: OrderOption.Asc,
        })
      );
    }
  };

  return (
    <Button
      className="custom-button-height"
      size="large"
      theme="secondary"
      label="조회"
      borderType="filled"
      handleClick={handleSearch}
    />
  );
}
