import Calendar from "@sellernote/_sds-v2/src/components/calendar/Calendar";

import { useSearch } from "./SearchContext";

export default function Eta() {
  const [{ eta }, setSearch] = useSearch();

  const handleEtaSelect = (selectedDate: string) => {
    setSearch({ eta: selectedDate });
  };

  return (
    <Calendar
      className="custom-label-color"
      size="default"
      type="datePicker"
      inputWidth={"161px"}
      labelInfo={{ label: "ETA" }}
      date={eta ?? ""}
      setDate={handleEtaSelect}
      getMinDate={() => "today"}
    />
  );
}
