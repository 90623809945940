import Calendar from "@sellernote/_sds-v2/src/components/calendar/Calendar";

import { useSearch } from "./SearchContext";

export default function Etd() {
  const [{ etd }, setSearch] = useSearch();

  const handleEtdSelect = (selectedDate: string) => {
    setSearch({ etd: selectedDate });
  };

  return (
    <Calendar
      className="custom-label-color"
      size="default"
      type="datePicker"
      inputWidth={"161px"}
      labelInfo={{
        label: (
          <>
            ETD<span className="required">*</span>
          </>
        ),
      }}
      date={etd}
      setDate={handleEtdSelect}
      getMinDate={() => "today"}
    />
  );
}
