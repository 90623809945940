import { FreightTypeOption } from "@sellernote/_shared/src/types/forwarding/schedule";
import SelectOption from "@sellernote/_sds-v2/src/components/form/SelectOption";

import { checkIsAirFreightType, checkIsSeaFreightType } from "./utils";

import { useSearch } from "./SearchContext";

export default function FreightType() {
  const [{ freightType }, setSearch] = useSearch();

  const handleFreightTypeSelect = (selectedFreightType: FreightTypeOption) => {
    const isCurrentAirSelected = checkIsAirFreightType(freightType);
    const isCurrentSeaSelected = checkIsSeaFreightType(freightType);
    const isNewAirSelected = checkIsAirFreightType(selectedFreightType);
    const isNewSeaSelected = checkIsSeaFreightType(selectedFreightType);

    const isAirToSeaChanged = isCurrentAirSelected && isNewSeaSelected;
    const isSeaToAirChanged = isCurrentSeaSelected && isNewAirSelected;

    if (isAirToSeaChanged || isSeaToAirChanged) {
      setSearch({
        polId: null,
        podId: null,
        freightType: selectedFreightType,
      });
      return;
    }

    setSearch({ freightType: selectedFreightType });
  };

  return (
    <SelectOption
      className="custom-label-color"
      width="130px"
      size="default"
      labelInfo={{
        label: (
          <>
            운송 방식<span className="required">*</span>
          </>
        ),
      }}
      selectedOptionValue={freightType}
      onSelect={handleFreightTypeSelect}
      options={[
        { label: "FCL", value: FreightTypeOption.FCL },
        { label: "LCL", value: FreightTypeOption.LCL },
        { label: "AIR", value: FreightTypeOption.Air },
      ]}
    />
  );
}
