import { useRef } from "react";

import { InputSearchWithOptionsHandlerList } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

import Eta from "./Eta";
import Etd from "./Etd";
import FreightType from "./FreightType";
import Pod from "./Pod";
import Pol from "./Pol";
import Search from "./Search";
import { SearchProvider } from "./SearchContext";
import Styled from "./index.styles";

export default function ScheduleSearchBar() {
  const polOptionHandlerListRef =
    useRef<InputSearchWithOptionsHandlerList>(null);
  const podOptionHandlerListRef =
    useRef<InputSearchWithOptionsHandlerList>(null);

  return (
    <SearchProvider>
      <Styled.searchBar>
        <FreightType />

        <Styled.partContainer>
          <Pol polOptionHandlerListRef={polOptionHandlerListRef} />

          <Pod podOptionHandlerListRef={podOptionHandlerListRef} />
        </Styled.partContainer>

        <Styled.partContainer>
          <Etd />

          <Eta />
        </Styled.partContainer>

        <Search
          polOptionHandlerListRef={polOptionHandlerListRef}
          podOptionHandlerListRef={podOptionHandlerListRef}
        />
      </Styled.searchBar>
    </SearchProvider>
  );
}
