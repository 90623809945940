import { queryTypes, useQueryStates } from "next-usequerystate";

import {
  FreightTypeOption,
  OrderOption,
  SortOption,
  TransitTypeOption,
} from "@sellernote/_shared/src/types/forwarding/schedule";
import {
  addDate,
  getAppTodayMidnight,
} from "@sellernote/_shared/src/utils/common/date";

export default function useScheduleSearchBarQueryStates() {
  return useQueryStates({
    freightType: queryTypes
      .stringEnum<FreightTypeOption>(Object.values(FreightTypeOption))
      .withDefault(FreightTypeOption.FCL),
    polId: queryTypes.integer,
    podId: queryTypes.integer,
    etd: queryTypes.string.withDefault(
      addDate({
        date: getAppTodayMidnight(),
        value: 3,
        unit: "day",
      }).toISOString()
    ),
    eta: queryTypes.string,

    page: queryTypes.integer.withDefault(0),

    minTransitDay: queryTypes.integer,
    maxTransitDay: queryTypes.integer,
    filterPodIds: queryTypes.array(queryTypes.integer),
    filterPolIds: queryTypes.array(queryTypes.integer),
    transit: queryTypes.stringEnum<TransitTypeOption>(
      Object.values(TransitTypeOption)
    ),
    linerIdList: queryTypes.array(queryTypes.integer),

    sort: queryTypes
      .stringEnum<SortOption>(Object.values(SortOption))
      // TODO: 이건 고민.. 기본값을 없앨지 말지
      .withDefault(SortOption.TransitTimeInDays),
    order: queryTypes
      .stringEnum<OrderOption>(Object.values(OrderOption))
      // TODO: 이건 고민.. 기본값을 없앨지 말지
      .withDefault(OrderOption.Asc),

    /**
     * SearchBar의 '조회' 버튼으로 검색한 것인지 여부
     *  - true인 경우에는 비활성화 처리된 Filter를 로딩 컴포넌트처럼 사용한다.
     */
    isSearchBar: queryTypes.boolean.withDefault(true),
  });
}
